import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { get } from 'lodash';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { COST_ICON_BY_ENGINE_TYPE_MAP } from 'site-modules/shared/constants/range-and-cost';
import { getPriceString } from 'site-modules/shared/utils/price-utils';

export function CostToDriveInsight({ modelName, fuelCostsData, stateName }) {
  const engineType = get(fuelCostsData, 'vehicleData.engineType');
  const costPerMonth = get(fuelCostsData, 'fuelCosts.costPerMonth', 0);
  const avgCostPerMonth = get(fuelCostsData, 'statistics.byBodyTypeAndSize.combustionEngines.averageCostPerMonth', 0);
  const averageCategory = get(fuelCostsData, 'vehicleData.primaryBodyType', '');

  return (
    <div className="cost-to-drive-insight" data-tracking-parent="edm-entry-cost-to-drive">
      <Row className="pos-r mb-1_5">
        <Col xs={6} className="text-center">
          <i
            className={classnames(
              'size-24 type-icon mb-0_5',
              COST_ICON_BY_ENGINE_TYPE_MAP[engineType] || COST_ICON_BY_ENGINE_TYPE_MAP.default
            )}
          />
          <div className={classnames({ 'heading-4': costPerMonth, 'heading-5': !costPerMonth })}>
            {costPerMonth ? <Fragment>{getPriceString(costPerMonth)}/mo</Fragment> : 'Not EPA Tested'}
          </div>
          <div className="medium">{modelName}</div>
        </Col>
        {!!avgCostPerMonth && (
          <Fragment>
            <div className="vs-separator pos-a center-x h-100">
              <div className="pos-a center-xy bg-white font-weight-bold text-gray-dark medium py-0_25">vs</div>
            </div>
            <Col xs={6} className="text-center">
              <i className={classnames('size-24 text-cool-gray-50 mb-0_5', COST_ICON_BY_ENGINE_TYPE_MAP.default)} />
              <div className="size-20 font-weight-bold text-cool-gray-50">{getPriceString(avgCostPerMonth)}/mo</div>
              <div className="medium">
                <Fragment>{averageCategory || 'Similar'} Avg.</Fragment>
              </div>
            </Col>
          </Fragment>
        )}
      </Row>
      <div className="medium">Monthly estimates based on costs in {stateName}</div>
    </div>
  );
}

CostToDriveInsight.propTypes = {
  modelName: PropTypes.string.isRequired,
  fuelCostsData: VehicleEntities.StyleFuelCostData,
  stateName: PropTypes.string,
};

CostToDriveInsight.defaultProps = {
  fuelCostsData: null,
  stateName: null,
};
