import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PhotoflipperButtonAsync } from 'site-modules/shared/components/photoflipper-core/photoflipper-button/photoflipper-button-async';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { EDMUNDS_CONTENT } from 'site-modules/shared/utils/core-page/core-media';
import { SOURCES, getImageUrl } from 'client/utils/image-helpers';
import { getPhotoflipperProps, OVERLAY_TYPE, PHOTO_ROW_THRESHOLD } from 'site-modules/core-page/utils/photo-gallery';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import { TextOverlay } from './overlays/text-overlay';

export function PhotoGalleryPhoto({
  photoData,
  params,
  media,
  isPreprod,
  overlayType,
  totalCount,
  index,
  isHeroPhoto,
  hasSpinPhotos,
}) {
  const { num, title, src, isLifestylePhoto, author, provider } = photoData;
  const imgUrl = getImageUrl(
    src,
    undefined,
    isHeroPhoto && params.isReviewSubpage ? SOURCES.EDMUNDSCDN : SOURCES.MEDIA
  );
  const is360Tab = overlayType === OVERLAY_TYPE.PHOTOS_360;
  const photoflipperProps = getPhotoflipperProps({
    photoNum: is360Tab ? 1 : num,
    params,
    altMedia: media,
    isPreprod,
    hasSpinPhotos,
    is360Tab,
  });

  // -1 to exclude main photo as well
  const restCount = totalCount - PHOTO_ROW_THRESHOLD - 1;
  const photoTrackingId = isHeroPhoto ? 'open_photoflipper_hero' : 'open_photoflipper_thumbnail';
  return (
    <div className="pos-r">
      <PhotoflipperButtonAsync
        tag={Fragment}
        {...photoflipperProps}
        dataTrackingId={is360Tab ? 'open_photoflipper_360' : photoTrackingId}
      >
        <AspectRatioContainer
          aspectRatio={isHeroPhoto ? undefined : '15:9'}
          role="button"
          className="photo-gallery-photo rounded-12"
          data-tracking-value={index}
          data-tracking-parent={is360Tab ? '360-indication-thumbnail' : 'photoflipper'}
        >
          <img
            className="photo w-100"
            alt={title}
            title={title}
            src={imgUrl}
            data-lifestyle-photo={isLifestylePhoto}
            fetchpriority={isHeroPhoto ? 'high' : 'low'}
            loading={isHeroPhoto ? 'eager' : 'lazy'}
          />
          {provider === EDMUNDS_CONTENT && !!author && (
            <div
              className="small text-cool-gray-10 pos-a"
              style={{
                transform: 'none',
                width: 'auto',
                top: 'auto',
                left: '0',
                bottom: '0',
                padding: '0.25rem',
                backgroundColor: 'rgba(255, 255, 255, 0.65)',
              }}
            >
              <span className="sr-only">photo by </span>
              {author} | Edmunds
            </div>
          )}
          <span className="sr-only">View more photos</span>
        </AspectRatioContainer>
      </PhotoflipperButtonAsync>
      {overlayType === OVERLAY_TYPE.REST_PHOTOS && restCount > 0 && <TextOverlay text={`+${restCount}`} />}
      {overlayType === OVERLAY_TYPE.PHOTOS_360 && <TextOverlay text={'360°'} />}
    </div>
  );
}

PhotoGalleryPhoto.propTypes = {
  params: CorePageParams.isRequired,
  photoData: PropTypes.shape({}).isRequired,
  media: PropTypes.shape({
    photos: PropTypes.arrayOf(PropTypes.shape({})),
    videos: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  totalCount: PropTypes.number,
  index: PropTypes.number,
  overlayType: PropTypes.oneOf(Object.values(OVERLAY_TYPE)),
  hasSpinPhotos: PropTypes.bool,
  isPreprod: PropTypes.bool,
  isHeroPhoto: PropTypes.bool,
};

PhotoGalleryPhoto.defaultProps = {
  media: null,
  totalCount: 0,
  index: undefined,
  overlayType: null,
  hasSpinPhotos: false,
  isPreprod: false,
  isHeroPhoto: false,
};
