import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { EvInsightsEntities, EvInsightsModel, EvInsightsPaths, INVENTORY_CODE } from 'client/data/models/ev-insights';
import { buildReviewPath, EditorialReviewModel } from 'client/data/models/editorial-review';
import { getParamsFromVehicle } from 'site-modules/shared/utils/core-page/params';
import {
  buildEvTestedDataPath,
  buildFuelCostsPath,
  VehicleEntities,
  VehicleModel,
} from 'client/data/models/vehicle-v2';
import { VisitorEntities, VisitorModel } from 'client/data/models/visitor';
import { FeatureFlagModel } from 'client/data/models/feature-flag';
import { EDMUNDS_LOGO_NO_TEXT } from 'client/images/inline-svgs/edmunds-logo';
import { EV_TESTED_DATA_START_DATE } from 'site-modules/shared/constants/range-and-cost';
import { getUsedYears } from 'site-modules/shared/utils/core-page/ev-insights';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { ReadyForEv, ReadyForEvTreehouse } from 'site-modules/shared/components/ready-for-ev/ready-for-ev';
import { RangeInsight } from 'site-modules/shared/components/range-insight/range-insight';
import { TreehousePromotionCheck } from 'site-modules/shared/components/treehouse-promotion/treehouse-promotion-check';
import { InsightAd } from 'site-modules/shared/components/native-ad/insight-ad/insight-ad';
import { ChargingInsight } from 'site-modules/shared/components/charging-insight/charging-insight';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
import { BatteryLifeInsight } from './insights/battery-life-insight/battery-life-insight';
import { IncentivesInsight } from './insights/incentives-insight/incentives-insight';
import { CostToDriveInsight } from './insights/cost-to-drive-insight/cost-to-drive-insight';
import { IncentivesWizardInsight } from './insights/incentives-wizard-insight/incentives-wizard-insight';
import { InsightCard } from './insight-card';

const CREATIVE_ID = {
  RANGE: 'edm-entry-range',
  BATTERY_LIFE: 'edm-entry-battery-life',
  INCENTIVES: 'edm-entry-ev-tax-credits',
  CHARGING: 'edm-entry-charging',
  COST_TO_DRIVE: 'edm-entry-cost-to-drive',
  TREEHOUSE_PROMOTION: 'treehouse-promotion',
};

const TOOLTIP_TRACKING_DATA = {
  'range-insight': { creative_id: CREATIVE_ID.RANGE, value: 'Edmunds Tested Range' },
  'range-map': { creative_id: CREATIVE_ID.RANGE, value: 'Range Map' },
  'range-battery': { creative_id: CREATIVE_ID.RANGE, value: 'Battery Life' },
  'range-estimated': { creative_id: CREATIVE_ID.RANGE, value: 'Estimated Range' },
  'battery-life-insight-warranty': { creative_id: CREATIVE_ID.BATTERY_LIFE, value: 'Battery Warranty' },
  'battery-life-insight-remaining': { creative_id: CREATIVE_ID.BATTERY_LIFE, value: 'Warranty Remaining' },
  'charging-port-insight': { creative_id: CREATIVE_ID.CHARGING, value: 'Charging Port' },
  'home-charging': { creative_id: CREATIVE_ID.CHARGING, value: 'Home Charging' },
  'fast-charging': { creative_id: CREATIVE_ID.CHARGING, value: 'Fast Charging' },
};

function InsightsCardsUI({
  vehicle: {
    make: { name: makeName, slug: makeSlug },
    model: { name: modelName, slug: modelSlug },
    year,
  },
  styleId,
  setModelValue,
  evInsightsData,
  styleEvTestedData,
  fuelCostsData,
  reviewEstimatedRange,
  visitorLocation,
  disableEvIncentivesWizard,
  isPluginHybrid,
  isUsed,
  isMobile,
}) {
  const onTooltipOpen = useCallback(({ id }) => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_VIEW_CONTENT,
        subaction_name: TrackingConstant.VIEW_TOOLTIP,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        ...TOOLTIP_TRACKING_DATA[id],
      },
    });
  }, []);

  if (!fuelCostsData) {
    return null;
  }

  const testedRange = get(styleEvTestedData, 'range');
  const epaRange = get(evInsightsData, 'style.epaSpecifications.electricityRange');
  const usedYears = getUsedYears({ year, isUsed });

  const batteryWarranty = get(evInsightsData, 'style.batteryWarranty');
  const hybridComponentsWarranty = get(evInsightsData, 'style.hybridComponentsWarranty');

  const batteryLifeInsight = (
    <InsightCard
      heading={batteryWarranty || !hybridComponentsWarranty ? 'EV Battery Warranty' : 'Hybrid Parts Warranty'}
      creativeId={CREATIVE_ID.BATTERY_LIFE}
    >
      <BatteryLifeInsight
        batteryWarranty={batteryWarranty}
        hybridComponentsWarranty={hybridComponentsWarranty}
        onTooltipOpen={onTooltipOpen}
        usedYears={usedYears}
      />
    </InsightCard>
  );

  const evRangeHeading = isUsed ? 'Estimated Range Based on Age' : 'Range';

  const edmundsTestedBadge = (
    <div
      className="tested-badge px-0_75 py-0_25 font-weight-medium small text-primary-darker text-nowrap d-flex align-items-center"
      style={{ letterSpacing: '1px' }}
    >
      {EDMUNDS_LOGO_NO_TEXT({ width: 18, fillColor: '#0069bf' })}&nbsp;edmunds&nbsp;
      <span className="font-weight-bold text-cool-gray-50" style={{ letterSpacing: '3px' }}>
        TESTED
      </span>
    </div>
  );

  const readyForEv = !isPluginHybrid && (
    <InsightCard heading="Am I Ready for an EV?">
      <ReadyForEv isMobileLayout />
    </InsightCard>
  );

  const incentivesInsight = (
    <InsightCard heading="EV Tax Credits & Rebates" creativeId={CREATIVE_ID.INCENTIVES}>
      <IncentivesInsight
        incentives={get(evInsightsData, 'incentives')}
        makeName={makeName}
        modelName={modelName}
        zipCode={get(visitorLocation, 'zipCode')}
        isPluginHybrid={isPluginHybrid}
      />
    </InsightCard>
  );

  const incentivesWizardInsight = (
    <InsightCard heading="EV Tax Credits & Rebates" creativeId={CREATIVE_ID.INCENTIVES}>
      <IncentivesWizardInsight
        incentives={get(evInsightsData, 'wizardIncentives')}
        setModelValue={setModelValue}
        styleId={styleId}
        makeName={makeName}
        modelName={modelName}
        makeSlug={makeSlug}
        modelSlug={modelSlug}
        year={year}
        engineType={evInsightsData?.style?.engine?.engineType}
        zipCode={visitorLocation?.zipCode}
        isUsed={isUsed}
        isMobile={isMobile}
      />
    </InsightCard>
  );

  const rangeInsightProps = {
    vehicleName: `${year} ${makeName} ${modelName}`,
    modelName,
    year,
    testedRange,
    epaRange,
    onTooltipOpen,
    visitorLocation,
    isUsed,
    isPluginHybrid,
  };

  return (
    <div className="insights-cards mb-1_5" style={{ columns: isMobile ? 1 : 2 }}>
      <InsightCard
        heading={isPluginHybrid ? 'All Electric Range' : evRangeHeading}
        creativeId={CREATIVE_ID.RANGE}
        badge={testedRange ? edmundsTestedBadge : undefined}
      >
        {!testedRange && !epaRange && reviewEstimatedRange ? (
          <Experiment name="core-6454-est-ev" showDefault>
            <Recipe name="ctrl" isDefault>
              <RangeInsight {...rangeInsightProps} />
            </Recipe>
            <Recipe name="chal">
              <RangeInsight {...rangeInsightProps} reviewEstimatedRange={reviewEstimatedRange} />
            </Recipe>
          </Experiment>
        ) : (
          <RangeInsight {...rangeInsightProps} />
        )}
      </InsightCard>
      {isMobile && batteryLifeInsight}
      <InsightCard tag={ScrollElement} id="subnav-ev-charging" heading="Charging" creativeId={CREATIVE_ID.CHARGING}>
        <ChargingInsight
          chargeTime={get(evInsightsData, 'style.epaSpecifications.batteryChargeTime')}
          portType={get(evInsightsData, 'style.engine.electricMotor.fastChargePortType')}
          testedChargeTime={get(styleEvTestedData, 'totalChargingTimeMMSS')}
          onTooltipOpen={onTooltipOpen}
        />
      </InsightCard>
      {!isMobile && batteryLifeInsight}
      <InsightAd
        mobile={isMobile}
        style={isMobile ? undefined : { breakAfter: 'column' }}
        wrapperClass="insight-card mb-1"
      />
      {disableEvIncentivesWizard ? (
        incentivesInsight
      ) : (
        <Fragment>
          {isUsed ? (
            <Experiment name="core-6416-usedincentivewiz" showDefault>
              <Recipe name="ctrl" isDefault>
                {incentivesInsight}
              </Recipe>
              <Recipe name="chal">{incentivesWizardInsight}</Recipe>
            </Experiment>
          ) : (
            incentivesWizardInsight
          )}
        </Fragment>
      )}
      {!isEmpty(fuelCostsData) && (
        <InsightCard heading="Cost to Drive" creativeId={CREATIVE_ID.COST_TO_DRIVE}>
          <CostToDriveInsight
            modelName={modelName}
            fuelCostsData={fuelCostsData}
            stateName={get(visitorLocation, 'state')}
          />
        </InsightCard>
      )}
      <TreehousePromotionCheck fallback={readyForEv}>
        <InsightCard
          heading={isPluginHybrid ? 'Am I Ready for a Plug-In Hybrid?' : 'Am I Ready for an EV?'}
          creativeId={CREATIVE_ID.TREEHOUSE_PROMOTION}
        >
          <ReadyForEvTreehouse isMobileLayout creativeId={CREATIVE_ID.TREEHOUSE_PROMOTION} />
        </InsightCard>
      </TreehousePromotionCheck>
    </div>
  );
}

InsightsCardsUI.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  setModelValue: PropTypes.func.isRequired,
  styleId: PropTypes.number,
  evInsightsData: EvInsightsEntities.EvInsightsData,
  styleEvTestedData: VehicleEntities.StyleEvTestedData,
  fuelCostsData: VehicleEntities.StyleFuelCostData,
  reviewEstimatedRange: PropTypes.number,
  visitorLocation: VisitorEntities.Location,
  disableEvIncentivesWizard: PropTypes.bool,
  isPluginHybrid: PropTypes.bool,
  isUsed: PropTypes.bool,
  isMobile: PropTypes.bool,
};

InsightsCardsUI.defaultProps = {
  styleId: null,
  evInsightsData: null,
  styleEvTestedData: null,
  fuelCostsData: null,
  reviewEstimatedRange: null,
  visitorLocation: null,
  disableEvIncentivesWizard: false,
  isPluginHybrid: false,
  isUsed: false,
  isMobile: false,
};

export const propsAreEqual = (
  prevProps,
  { evInsightsData, fuelCostsData, styleEvTestedData, vehicle, isPluginHybrid }
) =>
  (!isPluginHybrid && !styleEvTestedData && vehicle.year >= EV_TESTED_DATA_START_DATE) ||
  !evInsightsData ||
  !fuelCostsData;
export const InsightsCards = connectToModel(React.memo(InsightsCardsUI, propsAreEqual), {
  visitorLocation: bindToPath('location', VisitorModel),
  styleEvTestedData: bindToPath(
    ({ styleId, vehicle, isPluginHybrid }) =>
      !isPluginHybrid && vehicle.year >= EV_TESTED_DATA_START_DATE && buildEvTestedDataPath([styleId]),
    VehicleModel,
    evTestedData => get(evTestedData, '[0]', {})
  ),
  evInsightsData: bindToPath(
    ({ styleId, isUsed }) =>
      EvInsightsPaths.getEvInsightsDataPath(styleId, isUsed ? INVENTORY_CODE.USED : INVENTORY_CODE.NEW),
    EvInsightsModel
  ),
  fuelCostsData: bindToPath(({ styleId }) => buildFuelCostsPath(styleId), VehicleModel),
  reviewEstimatedRange: bindToPath(
    ({ vehicle }) => buildReviewPath(getParamsFromVehicle(vehicle)),
    EditorialReviewModel,
    ({ estimatedRange }) => estimatedRange
  ),
  disableEvIncentivesWizard: bindToPath('disable-ev-incentives-wizard', FeatureFlagModel),
});
